const convertNullsToEmptyStrings = (data) => {
  const dataCopy = {};
  for (const [key, value] of Object.entries(data)) {
    dataCopy[key] = (value == null) ? '' : value;
  }
  return dataCopy;
};

const onEvent = (node, evt, fn) => {
  if (typeof node !== 'string') return;
  if (typeof evt !== 'string') return;
  if (typeof fn !== 'function') return;
  document.querySelector(node).addEventListener(evt, fn);
};

const onLoad = (fn) => {
  if (typeof fn !== 'function') return;
  window.addEventListener('load', fn);
};

const onReady = (fn) => {
  if (typeof fn !== 'function') return;
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    return fn();
  }
  document.addEventListener('DOMContentLoaded', fn, false);
};

const onScroll = (fn) => {
  if (typeof fn !== 'function') return;
  window.addEventListener('scroll', fn);
};

const validate = (value, defaultValue) => typeof value !== 'undefined' ? value : defaultValue;

export default {
  convertNullsToEmptyStrings,
  onEvent,
  onLoad,
  onReady,
  onScroll,
  validate,
};
