import utils from "./utils";
const { onLoad, onReady } = utils;

import Glide from '@glidejs/glide'
import "@glidejs/glide/src/assets/sass/glide.core";
import "@glidejs/glide/src/assets/sass/glide.theme";

//---------------------------------------------
//  Logo Carousel
//---------------------------------------------

const initHeroLogoCarousel = () => {
    onReady(() => {
        const $carousel = $("#js-tech-logos-hero");
        if (!$carousel.is(":visible")) return;

        const options = {
            yOrigin: 92,
            yRadius: 120,
            autoPlay: 1,
            bringToFront: true,
            itemClass: "tech-logo",
            frontItemClass: "active",
            onLoaded: () => {
                $carousel.css("visibility", "visible");
                $carousel.css("overflow", "visible");
                $carousel.fadeIn(1500);
            }
        };
        $carousel.Cloud9Carousel(options);
    });
};

onLoad(initHeroLogoCarousel);

//---------------------------------------------
//  Screenshot Carousel
//---------------------------------------------

const initScreenshotCarousel = () => {

    const className = 'glide';
    const elements = document.getElementsByClassName(className);
    if (!elements || elements.length < 1) return;

    new Glide('.' + className, {
        type: 'carousel',
        autoplay: 2000,
        focusAt: 'center',
        perView: 3,
        peek: {
            before: 60,
            after: 60
        },
        breakpoints: {
            768: {
                perView: 1,
            }
        }
    }).mount();
};

onReady(initScreenshotCarousel);

//const initScreenshotCarousel = () => {
//  onReady(() => {
//    SlickCarousel.init({
//      selector: '.screenshot-carousel',
//      centerMode: true,
//      slidesToShow: 3,
//      slidesToScroll: 1,
//      autoplay: true,
//      autoplaySpeed: 3000,
//      responsive: [
//        {
//          breakpoint: 992,
//          settings: { slidesToShow: 3, slidesToScroll: 3, infinite: true, dots: true, },
//        },
//        {
//          breakpoint: 768,
//          settings: { slidesToShow: 1, slidesToScroll: 1, },
//        },
//        {
//          breakpoint: 576,
//          settings: { slidesToShow: 1, slidesToScroll: 1, },
//        },
//      ],
//    });
//  });

//---------------------------------------------
