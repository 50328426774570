import { jarallax, jarallaxElement, jarallaxVideo } from 'jarallax';

import AOS from 'aos';
import "aos/src/sass/aos";

//$(() => {

//  const options = {
//    selector: '.jarallax',
//    speed: 0.5,
//    disableParallax: /iPad|iPhone|iPod|Android/,
//    disableVideo: /iPad|iPhone|iPod|Android/
//  };

//  jarallax(document.querySelectorAll(options.selector), options);

//  const $window = $(window);

//  const onResize = () => {
//    jarallax(document.querySelectorAll(options.selector), 'onResize');
//    $(document.querySelectorAll(options.selector)).css('width', `${$window.width()}px`);
//  };
//  $window.resize(onResize);
//  $window.on('orientationchange', onResize);
//});

AOS.init();

jarallax(document.querySelectorAll('.jarallax'), {
  speed: 0.5,
});
