import utils from "./utils";
const { onEvent, onReady, onScroll } = utils;

//---------------------------------------------
//  Sticky Header
//---------------------------------------------

const initStickyHeader = () => {
    onReady(() => {
        const offsetTop = 245;
        const navbar = document.querySelector('.navbar');
        onScroll(() => {
            if (window.scrollY > offsetTop) {
                navbar.classList.add('fixed-top', 'bg-dark');
                document.body.style.paddingTop = navbar.offsetHeight + 'px';
            } else {
                navbar.classList.remove('fixed-top', 'bg-dark');
                document.body.style.paddingTop = '0';
            }
        });
    });
};

initStickyHeader();

//---------------------------------------------
//  Sticky Sidebar with Nav
//---------------------------------------------

const updateStickySidebar = () => {
    const { sidebar } = window.AppHelper;
    if (sidebar) {
        sidebar.updateSticky();
    } else {
        initStickySidebar();
    }
};

const initStickySidebar = () => {
    const options = { selector: '.js-sticky-sidebar', rootMargin: `0px 0px -65%` };
    const $stickySidebar = $(`${options.selector}-content`);
    if (!$stickySidebar.length) return;
    const sidebar = new StickySidebar(`${options.selector}-outter`, {
        containerSelector: `${options.selector}-container`,
        innerWrapperSelector: `${options.selector}-inner`,
    });
    window.AppHelper.sidebar = sidebar;
    setTimeout(() => {
        updateStickySidebar();
        $('body').scrollspy({
            target: $stickySidebar[0],
            rootMargin: options.rootMargin,
        });
    }, 1000);
};

const initStickyNav = () => {

    const $container = $('.js-sticky-sidebar-container');
    const $autolinks = $('.js-sticky-sidebar-autolinks');
    if (!$container.length || !$autolinks.length) { return; }
    const $headings = $container.find('h3,h4,h5,h6');
    const $ul = $('<ul class="nav nav-pills">');
    $headings.each((index, element) => {
        const id = `id-${index}`;
        const $el = $(element);
        $el.attr('id', id);
        const $li = $(`<li class="nav-item"><a class="nav-link node-${$el[0].nodeName.toLowerCase()}" href="#${id}">${$el.text()}</a></li>`);
        $li.appendTo($ul);
    });
    const $html = $('<div class="d-sm-none d-lg-block" />');
    $ul.appendTo($html);
    $autolinks.html($html);
};

initStickySidebar();
initStickyNav();
onEvent('body', 'UpdateStickySidebar', updateStickySidebar);

//---------------------------------------------

//  //const $window = $(window);

//  const initClassyNav = () => {
//    if (!$.fn.classyNav) return;
//    $('.js-main-nav').classyNav();
//  };

//  const initScrollDown = () => {
//    const options = {
//      selector: '#scrollDown',
//      selectorTarget: '#scrollDownTarget',
//      offset: 75,
//      duration: 800
//    };
//    $(options.selector).on('click', () => {
//      $('html, body').animate({
//        scrollTop: $(options.selectorTarget).offset().top - options.offset,
//      }, options.duration);
//    });
//  };

//  const initScrollUp = () => {
//    const options = {
//      scrollSpeed: 1000,
//      scrollText: '<span class="fa fa-chevron-up"></span>',
//      animation: 'fade',
//      animationInSpeed: 5000,
//      animationOutSpeed: 5000,
//    };
//    // $(() => $window.scrollUp(options));
//  };
