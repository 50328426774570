import "core-js/stable";
import "regenerator-runtime/runtime";

import "./scss/main";
import utils from "./js/custom/utils";

window.AppHelper = window.AppHelper || {};

import "./js/ext/jquery.cloud9carousel";
import "./js/ext/resizeSensor.min";
import "./js/ext/sticky-sidebar.min.js";

//import "./js/custom/animation";
import "./js/custom/carousels";
//import "./js/custom/display";
import "./js/custom/navigation";
import "./js/custom/parallax";
//import "./js/custom/popups";
//import "./js/custom/preloader";

import { initBootstrap } from "./js/custom/bootstrap";

const { onReady } = utils;

initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

onReady(() => {
  console.log(`App ready: ${process.env.NAME}`);
});
